<template>
  <div class="window-modal-parent" v-bind="getAllData" v-if="visor">
    <div class="absolute centered wrapper" v-if="locale">
      <div class="window-modal" :class="{ loading: loading }" v-if="post" :record="post._id" :gender="post.gender" :priority="post.priority" :satelite="post.satelite" :key="post._id">
        <div class="window-modal-header">
          <div class="window-modal-header-title">Registro de Modelo</div>
          <div class="window-modal-header-options">
            <div class="window-modal-header-options-edit" v-if="someRole(['super'])">
              <a class="window-modal-header-options-button" @click="tab = 'info'" v-if="tab === 'edit'">Cancelar</a>
              <a class="window-modal-header-options-button" @click="tab = 'edit'" v-else>Editar</a>
            </div>
            <a class="window-modal-header-options-button" @click="modelView({ action: 'close' })">
              <i class="fa fa-times"></i>
            </a>
          </div>
        </div>
        <div class="window-modal-content">
          <div class="window-modal-content-arrow isLeft">
            <a @click="loadRecord((params.arrow = 'prev'))">
              <img src="@/assets/img/slide-left-hand.svg" />
            </a>
          </div>
          <div class="window-modal-content-wrap isModelInfo">
            <form class="window-modal-content-info" :id="`model-record-${post._id}`">
              <ModelsRecordEdit :post="post" v-if="tab === 'edit'">
                <div class="main-form-submit form-data-submit is-due">
                  <div class="form-data-input">
                    <input type="nosubmit" value="Guardar" readonly @click="sendUpdateReview(`model-record-${post._id}`)" />
                    <input type="hidden" name="modelID" :value="post._id" />
                  </div>
                  <div class="form-data-input">
                    <input type="cancel" value="Cancelar" @click="tab = 'info'" readonly />
                  </div>
                </div>
              </ModelsRecordEdit>
              <table class="table-info" @change="sendReview(`model-record-${post._id}`)" v-else>
                <tr v-if="someRole(['super'])" class="header-table">
                  <td>
                    <div role="option" priority>
                      <input type="checkbox" :id="`isPriority-${post._id}`" name="priority" value="high" class="mycheck circle" :checked="post.priority === 'high'" />
                      <label :for="`isPriority-${post._id}`">Prioridad</label>
                    </div>
                  </td>
                  <td>
                    <div role="option" gender>
                      <input type="checkbox" :id="`isMale-${post._id}`" name="gender" value="male" class="mycheck circle" :checked="post.gender === 'male'" />
                      <label :for="`isMale-${post._id}`">Masculino</label>
                    </div>
                  </td>
                  <td>
                    <div role="option" satelite>
                      <input type="checkbox" :id="`isSatelite-${post._id}`" name="satelite" value="satelite" class="mycheck circle" :checked="post.satelite === 'satelite'" />
                      <label :for="`isSatelite-${post._id}`">Satelite</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Nombre</td>
                  <td bold class="name">{{ post.name }}</td>
                </tr>
                <tr>
                  <td>Edad</td>
                  <td bold>{{ yearsDiff(post.birthDayDate, Date.now()) }} Años</td>
                </tr>
                <tr>
                  <td>Fecha de nacimiento</td>
                  <td bold>{{ dateFormat(post.birthDayDate) }}</td>
                </tr>
                <tr v-if="post.country">
                  <td>Pais</td>
                  <td bold>{{ countryName(post.country) }}</td>
                </tr>
                <tr>
                  <td>Ciudad</td>
                  <td bold>{{ cityName(post.city) }}</td>
                </tr>
                <tr v-if="post.neighborhood">
                  <td>Sector</td>
                  <td bold>{{ post.neighborhood }}</td>
                </tr>
                <tr>
                  <td>Teléfono</td>
                  <td bold>
                    <a :href="`tel:${post.phone}`" class="phone-info" v-html="showPhoneInfo(post)"></a>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td bold>
                    <a :href="`mailto:${post.email}`" class="blue-hover">{{ post.email }}</a>
                  </td>
                </tr>
                <tr>
                  <td>Contactar por</td>
                  <td bold>
                    <div v-for="(ctmode, ctmodeidx) in post.contactMode" :key="ctmodeidx">
                      {{ locale.words[ctmode] }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Ha sido modelo web</td>
                  <td bold>{{ locale.words[post.hasWebcam] }}</td>
                </tr>
                <tr>
                  <td>Disponibilidad</td>
                  <td bold>
                    <div v-for="(time, tidx) in post.timeAvailability" :key="tidx">
                      {{ locale.words[time] }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Fecha de registro</td>
                  <td bold v-html="`${dateFormat(post.date)} - ${timeAMPM(post.time)}`"></td>
                </tr>
                <tr>
                  <td>Redes sociales</td>
                  <td>
                    <div class="social">
                      <SocialButtons v-for="(socialNetwork, i) in setSocialNetwork" :social-network="socialNetwork.socialNetwork" :username="socialNetwork.username" :key="i" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Estado</td>
                  <td bold dcolor>
                    <router-link :to="`/admin/models/?filter=${post.adminStatus}&record=${post._id}#app`" class="goto-process-link nomark">
                      <span @click="modelView({ action: 'close' })">{{ locale.words[post.adminStatus] }} <i class="fa fa-external-link"></i></span>
                    </router-link>
                  </td>
                </tr>
                <tr v-if="isPathRegister">
                  <td>Entrevista</td>
                  <td bold dcolor>
                    <a class="linkTo link-i" @click.prevent="goInterview(post)">
                      <span>Información</span>
                      <i class="fa fa-link"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div v-html="`<i class=&quot;fa fa-commenting-o&quot;></i> <b>Mensaje</b>`"></div>
                    <div>
                      <td>{{ post.youMessage }}</td>
                    </div>
                  </td>
                </tr>
              </table>
            </form>
            <div class="window-modal-content-photos">
              <div class="photo-grid" v-if="post.photos">
                <div class="picture" v-for="(photo, idxphoto) in post.photos" :key="idxphoto">
                  <img :alt="photo.name" :src="photo.link" @click="$refs.myGallery.photoView({ action: 'open', idx: idxphoto, photos: post.photos })" />
                </div>
                <video v-if="post.videoModel" class="photo-grid__video" loop muted controls :src="post.videoModel">
                  <track label="Español" kind="captions" srclang="en" />
                </video>
              </div>
            </div>
          </div>
          <div class="window-modal-content-arrow isRight">
            <a @click="loadRecord((params.arrow = 'next'))">
              <img src="@/assets/img/slide-right-hand.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <GalleryVisor ref="myGallery"></GalleryVisor>
  </div>
</template>

<script>
  import store from "../../store/index.js";
  import api from "../../api";
  import global from "../../assets/js/global";
  import GalleryVisor from "../web/GalleryVisor";
  import SocialButtons from "../widgets/SocialButtons.vue";
  import ModelsRecordEdit from "./ModelsRecordEdit.vue";
  import globals from "../../assets/js/global";
  export default {
    components: { GalleryVisor, SocialButtons, ModelsRecordEdit },
    data: () => {
      return {
        visor: false,
        post: null,
        locale: null,
        loading: false,
        params: null,
        user: {},
        tab: "",
        countries: [],
      };
    },
    methods: {
      closeWithEsc({ key }) {
        if (key === "Escape" && this.visor) this.modelView({ action: "close" });
      },
      modelView: async function (params) {
        this.params = params;
        const mbody = document.querySelector("body");
        if (params.action == "open") {
          mbody.classList.add("onphoto");
          this.post = params.post;
          this.params.id = this.post._id;
          this.visor = true;
        } else if (params.action == "openload") {
          this.loadRecord(params);
          this.visor = true;
          mbody.classList.add("onphoto");
        } else if (params.action == "close") {
          this.visor = null;
          this.tab = "info";
          mbody.classList.remove("onphoto");
        }
      },
      loadRecord: async function () {
        let params = this.params;
        this.loading = true;
        const _host = `${process.env.VUE_APP_API_HOST}admin/models/?id=${params.id}&arrow=${params.arrow}`;
        const result = await api.get(_host);
        this.post = result.data.models[0];
        this.params.id = this.post._id;
        this.params.post = this.post;
        this.tab = "info";
        this.loading = false;
      },
      showPhoneInfo: function (model) {
        let html = "";
        const { inputPostalCode = "" } = model;
        const hasPostalCode = inputPostalCode.trim();
        if (hasPostalCode) {
          const { flag, name } = this.countries.filter((country) => country.callingCodes?.includes(inputPostalCode))[0];
          html = `
            <img src=${flag} alt=${name} />
            <span>+${inputPostalCode}</span>
            <span>${model.phone}</span>
        `;
        } else {
          html = `
          <span>${model.phone}</span>
        `;
        }
        return html;
      },
      someRole: function (checkRole) {
        const myRoles = this.user.roles;
        return global.hasSome(checkRole, myRoles);
      },
      sendUpdateReview: function (formId) {
        const isValidForm = this.$validate.validateForm("#" + formId);
        if (isValidForm) {
          return this.sendReview(formId);
        } else {
          return this.dataError("Por favor complete todos los datos");
        }
      },
      sendReview: async function (formId) {
        const _this = this;
        const _host = `${process.env.VUE_APP_API_HOST}admin/models/reviewAction`;
        const _data = new FormData(document.getElementById(formId));
        _data.append("cfilter", "record");
        _data.append("model", _this.post.email);
        this.dataSending();
        await this.$api
          .post(_host, _data)
          .then(function (response) {
            if (response.data.updated) {
              _this.dataSuccess(response.data);
            } else {
              _this.dataError(response.data.message);
            }
          })
          .catch(function (error) {
            _this.dataError(error);
          });
      },
      dataSending: function () {
        this.$global.loading(".window-modal-header-options");
      },
      dataSent: function (data) {
        this.$global.unloading(".window-modal-header-options");
        this.$global.setAttribute(`[record="${data.updated._id}"]`, "gender", data.updated.gender);
        this.$global.setAttribute(`[record="${data.updated._id}"]`, "priority", data.updated.priority);
        this.$global.setAttribute(`[record="${data.updated._id}"]`, "satelite", data.updated.satelite);
        this.tab = "info";
      },
      dataError: function (message) {
        this.$global.unloading(".window-modal-header-options");
        this.$swal.fire({
          title: "Error",
          html: message ? message : "Error desconocido",
          icon: "error",
          confirmButtonText: "Revisar",
          timerProgressBar: true,
          confirmButtonColor: "#bd0909",
        });
      },
      dataSuccess: function (data) {
        this.post = data.updated;

        this.$emit("updatedModel", data.updated);
        this.dataSent(data);
      },
      dateFormat: (date) => {
        return global.dateLocaleFormat(date);
      },
      timeAMPM: (time) => {
        return global.timeAMPM(time);
      },
      yearsDiff: (date1, date2) => {
        return global.yearsDiff(date1, date2);
      },
      getdataLang: async function () {
        this.user = await store.getters["user"];
        this.locale = await store.getters["localeLang"];
      },
      countryName: function (country) {
        return country[0].toUpperCase() + country.substring(1);
      },
      cityName: function (city) {
        if (this.locale.words[city]) {
          return this.locale.words[city];
        } else {
          return city;
        }
      },
      goInterview: async function (model) {
        let route = this.$router.resolve({ name: "interview", query: { email: model.email, sesion: this.$store.state.userProfile.SSID } });
        window.open(route.href, "_blank");
      },
    },
    computed: {
      setSocialNetwork() {
        const socialNetWorks = {
          facebookAccount: "facebook",
          instagramAccount: "instagram",
          tiktokAccount: "tiktok",
          onlyfansAccount: "onlyfans",
        };
        return Object.entries(this.post).reduce((acc, [key, value]) => {
          if (socialNetWorks[key] && value.trim()) {
            acc.push({
              socialNetwork: socialNetWorks[key],
              username: value,
            });
          }
          return acc;
        }, []);
      },
      isPathRegister: function () {
        const filter = this.$route.query;
        if (["new", "approved", "pending", "disapproved"].includes(filter?.filter)) {
          return false;
        }
        return true;
      },
      getAllData: async function () {
        this.loading = true;
        await this.getdataLang();
        this.loading = false;
        this.countries = await globals.getCountriesInfo();
      },
    },
    watch: {
      visor: function (val) {
        val ? document.addEventListener("keyup", this.closeWithEsc) : document.removeEventListener("keyup", this.closeWithEsc);
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";

  .window-modal-parent {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    z-index: 999;
    .wrapper {
      padding: $mpadding;
    }
    .linkTo {
      cursor: pointer;
    }

    .window-modal {
      position: relative;
      background-color: $white;
      max-width: 1150px;
      margin: auto;
      border-radius: $mradius;
      max-height: calc(100vh - #{$mpadding});
      overflow: hidden;
      overflow-y: auto;
      .phone-info {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          max-width: 25px;
        }
      }
      &-header {
        display: flex;
        justify-content: space-between;
        background-color: $white;
        border-bottom: solid 1px $alto;
        position: sticky;
        z-index: 2;
        top: 0;
        &-title {
          padding: $mpadding;
        }
        &-options {
          display: flex;
          align-items: center;
          &-button {
            display: inline-block;
            padding: $mpadding;
            cursor: pointer;
            &:hover {
              color: $primary_color;
            }
          }
          &.loading {
            > * {
              display: none;
            }
            &::after {
              right: 0;
              left: calc(100% - #{$mpadding * 3});
            }
          }
          &-edit {
            display: flex;
            align-items: center;
          }
        }
      }
      &-photos {
        margin-top: $mpadding;
        .photo-grid {
          column-count: 2;
          column-gap: $mpadding/2;
          img {
            width: auto;
            margin: $mpadding/2;
            max-width: calc(100% - #{$mpadding/2});
            border-radius: $mradius;
            box-shadow: $dshadow;
            cursor: ne-resize;
          }
          &__video {
            width: 100%;
            max-width: 500px;
            height: 100%;
            max-height: 80vh;
            object-fit: contain;
          }
        }
      }
      .form-data-submit {
        margin-top: $mpadding;
      }
    }
  }
  @media (min-width: $tablet_width) {
    .window-modal {
      &-content {
        padding: $mpadding;
        &-arrow {
          position: fixed;
          display: flex;
          align-items: center;
          top: 0;
          bottom: 0;
          padding: $mpadding/2;
          &.isLeft {
            left: $mpadding;
          }
          &.isRight {
            right: $mpadding;
          }
          > a {
            cursor: pointer;
          }
        }
        &-wrap {
          display: block;
          &.isModelInfo {
            margin: 0 $mpadding * 2;
          }
        }
        &-info {
          width: 100%;
          .table-info {
            width: 100%;
            & > tr > td {
              max-width: 200px;
              word-break: break-all;
              padding: $mpadding/2;
              display: block;
            }
            .name {
              font-size: 130%;
            }
          }
        }
        &-photos {
          margin-top: $mpadding;
          .photo-grid {
            column-count: 2;
            column-gap: $mpadding/2;
            img {
              width: auto;
              margin: $mpadding/2;
              max-width: calc(100% - #{$mpadding/2});
              border-radius: $mradius;
              box-shadow: $dshadow;
              cursor: ne-resize;
            }
            &__video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .form-data-submit {
          margin-top: $mpadding;
        }
      }
    }
    @media (min-width: $tablet_width) {
      .window-modal {
        &-content {
          &-arrow {
            position: absolute;
            &.isLeft {
              left: 0;
            }
            &.isRight {
              right: 0;
            }
          }
          &-wrap {
            display: flex;
          }
          &-info {
            width: 50%;
            margin-right: $mpadding/2;
            .table-info {
              > tr > td {
                display: table-cell;
                vertical-align: top;
              }
            }
          }
          &-photos {
            margin-top: 0;
            width: 50%;
          }
        }
      }
    }
  }
</style>
